import React from "react";

import IndexPage from "@app/pages";
import { User } from "@models/User";
import { OrganizationRepresentativeRoleList } from "@models/types/enums";
import LoginPage from "@pages/login";
import useAuthentication from "@services/authentication/useAuthentication";

interface GuardProps {
  children: JSX.Element;
}

const isModaresaAdmin = (currentUser: User) =>
  currentUser.role === "MODARESA_ADMIN";

const hasRepresentativeAccess = (currentUser: User) =>
  isModaresaAdmin(currentUser) ||
  OrganizationRepresentativeRoleList.includes(currentUser.role);

const hasBuyerAccess = (currentUser: User) =>
  isModaresaAdmin(currentUser) || currentUser.role === "CONTACT_BUYER";

export function AuthOnly({ children }: GuardProps) {
  const { currentUser } = useAuthentication();
  return currentUser && hasRepresentativeAccess(currentUser) ? (
    children
  ) : (
    <LoginPage />
  );
}

export function AuthBuyerOnly({ children }: GuardProps) {
  const { currentUser } = useAuthentication();
  return currentUser && hasBuyerAccess(currentUser) ? children : <LoginPage />;
}

export function withBuyerOnly(Component: React.ComponentType<{ user: User }>) {
  return function BuyerOnlyComponent() {
    const { currentUser } = useAuthentication();
    return currentUser && hasBuyerAccess(currentUser) ? (
      <Component user={currentUser} />
    ) : (
      <LoginPage />
    );
  };
}

export function GuestOnly({ children }: GuardProps) {
  const { currentUser } = useAuthentication();
  return currentUser ? <IndexPage /> : children;
}
