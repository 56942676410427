import React, { useMemo, useState } from "react";

import { useQueries } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import {
  bookedThroughColors,
  collectionsColors,
  formatColors,
} from "@app/modules/charts/chart-data";
import { AppointmentFormat } from "@models/types/enums";
import { GetShowroomAppointments } from "@services/api/showroom/get-appointments";
import { GetOngoingShowroomsEndpoint } from "@services/api/showroom/get-ongoing-showrooms";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";

import CustomDoughnutChart from "../charts/pie-chart";

interface AppointmentForStatusStatistics {
  id: string;
  collection: {
    id: string;
    name: string;
  } | null;
  format: AppointmentFormat | null;
  bookedThrough: "BUYER_JOURNEY" | "CALENDAR" | "SPECIAL_REQUEST";
  showroom: {
    id: string;
  };
  //! fix - choose the enum once it is merged later to main
}

export function calculateBookingDetailsStatistics(
  appointments: AppointmentForStatusStatistics[],
) {
  // booked through statistics
  // const totalAppointments = appointments.length;
  const bookedThroughBuyerJourney = appointments.filter(
    (appt) => appt.bookedThrough === "BUYER_JOURNEY",
  ).length;
  const bookedThroughCalendar = appointments.filter(
    (appt) => appt.bookedThrough === "CALENDAR",
  ).length;
  const bookedThroughSpecialRequest = appointments.filter(
    (appt) => appt.bookedThrough === "SPECIAL_REQUEST",
  ).length;

  // format statistics
  const virtualAppointments = appointments.filter(
    (appt) => appt.format === "VIRTUAL",
  ).length;
  const inPersonAppointments = appointments.filter(
    (appt) => appt.format === "IN_PERSON",
  ).length;

  return {
    bookedThroughBuyerJourney,
    bookedThroughCalendar,
    bookedThroughSpecialRequest,
    virtualAppointments,
    inPersonAppointments,
  };
}

// utils/collectionData.js

export function generateCollectionData(
  appointments: AppointmentForStatusStatistics[],
) {
  const collectionCountMap = new Map();
  let colorIndex = 0;

  const getNextColor = () => {
    const color = collectionsColors[colorIndex];
    colorIndex = (colorIndex + 1) % collectionsColors.length;
    return color;
  };

  appointments.forEach(({ collection }) => {
    // make sure that only buying appointments, and not walkthroughs are included
    if (collection !== null) {
      const { id, name } = collection;
      if (collectionCountMap.has(id)) {
        collectionCountMap.get(id).value += 1;
      } else {
        const color = getNextColor();
        collectionCountMap.set(id, { name, value: 1, color: `#${color}` });
      }
    }
  });

  return Array.from(collectionCountMap.values());
}

function BookingDetails() {
  const { t } = useTranslation();
  // organization Id
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  // showroom Ids
  const { data: allOngoingShowrooms = [] } =
    GetOngoingShowroomsEndpoint.useHook({
      organizationId,
    });

  // fetch appointments data
  const { data: rawAppointments } = useQueries({
    queries: allOngoingShowrooms.map((ongoingShowroom) => ({
      queryKey: GetShowroomAppointments.getQueryKeys({
        organizationId,
        showroomId: ongoingShowroom.id,
      }),
      queryFn: () =>
        GetShowroomAppointments.call({
          organizationId,
          showroomId: ongoingShowroom.id,
        }),
    })),
    combine: (queries) => ({
      data: queries.flatMap((q) => q.data || []),
      error: queries.find((q) => q.error)?.error,
      status:
        queries.find((q) => q.status === "error")?.status ||
        queries.find((q) => q.status === "pending")?.status ||
        "success",
    }),
  });

  // remove all "busy" slots
  const appointmentsForOngoingShowrooms: AppointmentForStatusStatistics[] =
    useMemo(
      () => rawAppointments.filter((appt) => appt.type !== "BUSY"),
      [rawAppointments],
    );

  const [selectedShowroomId, setSelectedShowroomId] = useState<string | "all">(
    "all",
  );
  // compute the booking details statistics
  const filteredAppointments = useMemo(() => {
    if (selectedShowroomId === "all") {
      return appointmentsForOngoingShowrooms;
    }

    return appointmentsForOngoingShowrooms.filter(
      (appointment) => appointment.showroom.id === selectedShowroomId,
    );
  }, [appointmentsForOngoingShowrooms, selectedShowroomId]);

  const {
    bookedThroughBuyerJourney,
    bookedThroughCalendar,
    bookedThroughSpecialRequest,
    virtualAppointments,
    inPersonAppointments,
  } = useMemo(
    () => calculateBookingDetailsStatistics(filteredAppointments),
    [filteredAppointments],
  );

  // collections data
  const collectionData = useMemo(
    () => generateCollectionData(filteredAppointments),
    [filteredAppointments],
  );

  // format data
  const formatData = [
    {
      name: "In Person",
      donutChart: "format_data",
      value: inPersonAppointments,
      color: formatColors[0],
    },
    {
      name: "Virtual",
      donutChart: "format_data",
      value: virtualAppointments,
      color: formatColors[1],
    },
  ];

  // bookedThrough data
  const bookedThroughData = [
    {
      name: "Buyer Journey",
      donutChart: "booked_through",
      value: bookedThroughBuyerJourney,
      color: bookedThroughColors[0],
    },
    {
      name: "Calendar",
      donutChart: "booked_through",
      value: bookedThroughCalendar,
      color: bookedThroughColors[1],
    },
    {
      name: "Special Request",
      donutChart: "booked_through",
      value: bookedThroughSpecialRequest,
      color: bookedThroughColors[2],
    },
  ];

  return (
    <>
      <div className="flex flex-row justify-between">
        <h3 className="font-bold">{t("Dashboard.booking-details.title")}</h3>
        {allOngoingShowrooms.length > 1 && (
          <div className="flex flex-row border border-transparent rounded-md">
            <p className="flex items-center text-sm p-1 bg-primaryLightestGrey text-primaryDarkGrey rounded-l-md">
              {t("Dashboard.invitation-status.for-showroom")}
            </p>
            <select
              id="seller-dropdown"
              value={selectedShowroomId}
              onChange={(e) => {
                setSelectedShowroomId(e.target.value as string);
              }}
              className="form-select block border rounded-r-md shadow-sm border-primaryElectricBlue focus:border-primaryElectricBlue bg-primaryLightElectricBlue sm:text-sm text-primaryElectricBlue caret-primaryElectricBlue"
            >
              <option value="all">All</option>
              {allOngoingShowrooms?.map((showroom) => (
                <option key={showroom.id} value={showroom.id}>
                  {showroom.name}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
      <div className="flex flex-col justify-center">
        <div className="flex flex-wrap md:flex-row justify-center">
          <div>
            {/* Booked Through Donut Chart */}
            <CustomDoughnutChart
              data={bookedThroughData}
              subtitle="Booked Through"
            />
          </div>
          <div>
            {/* Collections  Donut Chart */}
            <CustomDoughnutChart data={collectionData} subtitle="Collections" />
          </div>
          <div>
            {/* Format  Donut Chart */}
            <CustomDoughnutChart data={formatData} subtitle="Format" />
          </div>
        </div>
      </div>
    </>
  );
}

export default BookingDetails;
