/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useRef, useState } from "react";

import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  pointerWithin,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { Transition } from "@headlessui/react";
import { useQueries } from "@tanstack/react-query";
import {
  addMinutes,
  differenceInHours,
  format,
  max,
  min,
  parse,
} from "date-fns";
import { useTranslation } from "react-i18next";
import {
  HiChevronLeft,
  HiChevronRight,
  HiOutlinePencil,
} from "react-icons/hi2";
import { VscTrash } from "react-icons/vsc";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { groupBy } from "remeda";

import AppointmentDialogEdit, {
  AccountAppointmentFormData,
  AccountAppointmentFormDataSubmitted,
  appointmentToFormData,
} from "@calendar/components/appointment/dialog-edit";
import AppointmentDialogView from "@calendar/components/appointment/dialog-view";
import BusySlotCreateForm from "@calendar/components/busy/create-form";
import {
  BusyAppointmentEditForm,
  BusySlotEditForm,
} from "@calendar/components/busy/edit-form";
import DailyHeader from "@calendar/components/daily-header";
import CalendarFilters, {
  useCalendarFilters,
} from "@calendar/components/filters";
import SellerAgenda from "@calendar/components/seller-agenda";
import TimeColumn from "@calendar/components/time-column";
import CalendarHelper from "@calendar/helpers/calendar";
import { buildDayParams } from "@calendar/helpers/day-params";
import { COLUMN_WIDTH } from "@calendar/style";
import { Calendar } from "@calendar/types";
import Button from "@components/data-entry/Button";
import CalendarDatePicker from "@components/data-entry/CalendarDatePicker";
import SeasonSelect, {
  combineYearAndSeason,
} from "@components/data-entry/SeasonSelect";
import ConfirmModal from "@components/feedback/ConfirmModal";
import Drawer, { DrawerBody, useDrawer } from "@components/feedback/Drawer";
import Loading from "@components/feedback/Loading";
import ScrollView from "@components/layout/ScrollView";
import { arrayFilterDuplicateIds } from "@helpers/Array";
import {
  LocalDate,
  ZonedDate,
  combineDateAndTime,
  formatDateToISO,
} from "@helpers/Date";
import {
  AppointmentTypeEnum,
  BusyAppointmentTypeList,
  ShowroomSeason,
} from "@models/types/enums";
import { DeleteAppointmentEndpoint } from "@services/api/appointments/delete-appointment";
import { UpsertAppointmentEndpoint } from "@services/api/appointments/upsert-appointment";
import { CreateBusySlotsEndpoint } from "@services/api/busy-slots/create-busy-slots";
import { GetDailyCalendarEndpoint } from "@services/api/sales-campaigns/get-daily-calendar";
import { GetOngoingShowroomsEndpoint } from "@services/api/showroom/get-ongoing-showrooms";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import { useAuthenticatedUser } from "@services/authentication/useAuthentication";
import LogService from "@services/log/service";
import ConditionalRender from "@shared/components/on";
import { fullName } from "@shared/helpers/formatters";
import { getShowroomHoursOnDate } from "@shared/showroom/helpers";

export function useDailyCalendarSort(
  dailyCalendar: GetDailyCalendarEndpoint.Output | undefined,
) {
  const { associatedOrganizations } = useAuthenticatedUser();
  const representativeIds = associatedOrganizations.map(
    (b) => b.representativeId,
  );

  if (!dailyCalendar) {
    return undefined;
  }

  dailyCalendar.sort((seller1, seller2) => {
    // the current user is always first
    if (representativeIds.includes(seller1.id)) {
      return -1;
    }
    if (representativeIds.includes(seller2.id)) {
      return 1;
    }
    // otherwise they are sorted by name
    return fullName(seller1).localeCompare(fullName(seller2));
  });

  return dailyCalendar;
}

export const SALES_CAMPAIGN_DAILY_CALENDAR_PAGE_PATHNAME =
  "/sales-campaigns/:salesCampaignId/calendar/daily/:day";

function findShowroom<S extends { id: string }>(id: string, showrooms: S[]) {
  const showroom = showrooms.find((s) => s.id === id);
  if (!showroom) {
    throw new Error(`showroom with id ${id} not found`);
  }
  return showroom;
}

export default function DailyCalendarPage() {
  const { t } = useTranslation();
  /* GET PARAMS FROM URL/CONTEXT */
  const { season, year, day } = useParams<{
    season: ShowroomSeason;
    year: string;
    day: string;
  }>();

  if (!season || !year || !day) {
    throw new Error("bad URL");
  }

  const selectedDay = useMemo(
    () =>
      day === undefined ? new Date() : parse(day, "yyyy-MM-dd", new Date()),
    [day],
  );

  const selectedDayAsString: string = formatDateToISO(selectedDay);
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  /* UI HOOKS */
  const [wildcardFilter, setWildcardFilter] = useState("");
  const [isOpenCancelDialog, setIsOpenCancelDialog] = useState<boolean>(false);
  const navigate = useNavigate();
  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10,
    },
  });
  const sensors = useSensors(mouseSensor);

  /* DATA FETCHING */
  const {
    data: allOngoingShowrooms = [],
    status: allOngoingShowroomsStatus,
    error: allOngoingShowroomsError,
  } = GetOngoingShowroomsEndpoint.useHook({ organizationId });

  const allSeasonShowrooms = useMemo(
    () =>
      allOngoingShowrooms.filter(
        (s) => s.season === season && s.year === parseInt(year, 10),
      ),
    [JSON.stringify(allOngoingShowrooms), season, year],
  );

  const calendarFilters = useCalendarFilters({
    showrooms: allSeasonShowrooms,
    onFilterChange: setWildcardFilter,
  });

  const selectedShowrooms = allSeasonShowrooms.filter((s) =>
    calendarFilters.selectedShowroomIds.includes(s.id),
  );

  const {
    data: rawDailyCalendar,
    status: dailyCalendarFetchStatus,
    error: dailyCalendarError,
  } = useQueries({
    queries: selectedShowrooms.map((showroom) =>
      GetDailyCalendarEndpoint.query({
        organizationId,
        showroomId: showroom.id,
        dayAsString: selectedDayAsString,
      }),
    ),
    combine: (queries) => ({
      data: queries
        .flatMap((q) => q.data || [])
        .reduce((acc, seller) => {
          const existingSeller = acc.find((s) => s.id === seller.id);
          if (existingSeller) {
            return acc.map((s) =>
              s.id === seller.id
                ? {
                    ...s,
                    appointments: [...s.appointments, ...seller.appointments],
                  }
                : s,
            );
          }
          return [...acc, seller];
        }, [] as GetDailyCalendarEndpoint.Output),
      error: queries.find((q) => q.error)?.error,
      status:
        queries.find((q) => q.status === "error")?.status ||
        queries.find((q) => q.status === "pending")?.status ||
        "success",
    }),
  });
  const dailyCalendar = useDailyCalendarSort(rawDailyCalendar);

  const componentRef = useRef<HTMLDivElement | null>(null);
  const reactToPrintContent = React.useCallback(() => componentRef.current, []);

  const pageWidth = dailyCalendar ? dailyCalendar.length * 50 : 100;
  const minOpeningHour = min(
    selectedShowrooms.map(
      (s) => getShowroomHoursOnDate(selectedDay, s).opening,
    ),
  );
  const maxClosingHour = max(
    selectedShowrooms.map(
      (s) => getShowroomHoursOnDate(selectedDay, s).closing,
    ),
  );

  const pageHeight = differenceInHours(maxClosingHour, minOpeningHour) * 40;

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    pageStyle: `
    @media print {
      body { 
        -webkit-print-color-adjust: exact;
      }
      
      @page {
        size: ${pageWidth}mm ${pageHeight}mm;
      }
    }`,
  });

  /* DATA COMPUTING */
  const isLoading =
    dailyCalendarFetchStatus === "pending" ||
    allOngoingShowroomsStatus === "pending";
  const isError =
    dailyCalendarFetchStatus === "error" ||
    allOngoingShowroomsStatus === "error";
  const error =
    allOngoingShowroomsStatus === "error"
      ? allOngoingShowroomsError
      : dailyCalendarError;

  const [selectedAccountAppointment, setSelectedAccountAppointment] =
    useState<Calendar.AccountAppointment>();
  const [selectedBusyAppointment, setSelectedBusyAppointment] =
    useState<Calendar.BusyAppointment>();
  const [selectedAvailableSlot, setSelectedAvailableSlot] = useState<{
    start: Date;
    end: Date;
    sellerId: string;
    sellerName: string;
  }>();
  const [accountAppointmentFormData, setAccountAppointmentFormData] =
    useState<AccountAppointmentFormData>();
  const appointmentDrawer = useDrawer({
    backdrop: true,
    onClose: () => {
      setSelectedAccountAppointment(undefined);
      setAccountAppointmentFormData(undefined);
    },
  });
  const busyAppointmentDrawer = useDrawer({
    backdrop: true,
    onClose: () => {
      setSelectedBusyAppointment(undefined);
    },
  });

  /* MUTATIONS */
  const createBusySlotMutation = CreateBusySlotsEndpoint.useHook({
    organizationId,
  });

  const upsertMutation = UpsertAppointmentEndpoint.useHook({
    organizationId,
    dayAsString: selectedDayAsString,
  });
  const deleteMutation = DeleteAppointmentEndpoint.useHook({
    organizationId,
    dayAsString: selectedDayAsString,
  });

  const [showFilters, setShowFilters] = useState(true);

  /* HANDLERS */
  const handleSelectDate = (date: Date) => {
    navigate(`/calendar/${season}/${year}/daily/${format(date, "yyyy-MM-dd")}`);
  };

  const handleEditSelectedAccountAppointment = (
    appointment: Calendar.AccountAppointment,
  ) => {
    setAccountAppointmentFormData(appointmentToFormData(appointment));
  };

  const handleClickAppointmentSlot = (appointment: Calendar.Appointment) => {
    if (Calendar.checkIsBusyAppointment(appointment)) {
      setSelectedBusyAppointment(appointment);
      busyAppointmentDrawer.openDrawer();
    } else {
      setAccountAppointmentFormData(undefined);
      setSelectedAccountAppointment(appointment);
      appointmentDrawer.openDrawer();
    }
  };

  const handleClickAvailableSlot = (startTime: Date, sellerId: string) => {
    setSelectedAccountAppointment(undefined);
    setAccountAppointmentFormData({
      id: null,
      showroomId: selectedShowrooms[0].id,
      accountId: null,
      collectionId: null,
      contacts: [],
      date: selectedDay,
      format: null,
      sellerId,
      startTime,
      endTime: addMinutes(startTime, 30),
      type: null,
      virtualMeetingApp: null,
      accountOtb: null,
    });
    appointmentDrawer.openDrawer();
  };

  const handleBlockAvailableSlot = (startTime: Date, sellerId: string) => {
    const sellerName = fullName(
      allOngoingShowrooms
        .flatMap((s) => s.sellers)
        .find((s) => s.id === sellerId) || {
        firstName: "---",
        lastName: "---",
      },
    );

    setSelectedBusyAppointment(undefined);
    setSelectedAvailableSlot({
      start: startTime,
      end: addMinutes(startTime, 60),
      sellerId,
      sellerName,
    });
    busyAppointmentDrawer.openDrawer();
  };

  const handleCancelAppointment = (appointment: Calendar.Appointment) => {
    deleteMutation.mutate(
      { appointmentId: appointment.id },
      {
        onSuccess: appointmentDrawer.closeWithoutConfirmation,
      },
    );
  };

  const handleSubmitAppointment = (
    formData:
      | AccountAppointmentFormDataSubmitted
      | BusyAppointmentEditForm.FormDataValidated,
  ): Promise<void> =>
    new Promise<void>((resolve, reject) => {
      const showroom = allSeasonShowrooms.find(
        (s) => s.id === formData.showroomId,
      );
      if (!showroom) {
        throw new Error("appointment creation did not match any showroom");
      }

      // add account form data if it is set
      const typeSpecificData =
        "type" in formData
          ? {
              account: {
                id: formData.accountId,
              },
              attendees: formData.contacts.map((c) => ({
                id: c.value,
              })),
              collection: formData.collectionId
                ? { id: formData.collectionId }
                : null,
              format: formData.format,
              virtualMeetingApp: formData.virtualMeetingApp,
              type: formData.type,
              title: null,
            }
          : {
              title: formData.title,
              account: null,
              attendees: [],
              collection: null,
              format: null,
              virtualMeetingApp: null,
              type: BusyAppointmentTypeList[0],
            };

      const mutationData = {
        appointment: {
          ...typeSpecificData,
          id: formData.id,
          startTime: ZonedDate.fromLocalDate(
            formData.startTime as LocalDate,
            showroom.timezone,
          ),
          endTime: ZonedDate.fromLocalDate(
            formData.endTime as LocalDate,
            showroom.timezone,
          ),
          showroom: {
            id: showroom.id,
            timezone: showroom.timezone,
          },
          seller: {
            id: formData.sellerId,
          },
          accountOtb: formData.accountOtb,
        },
      };

      upsertMutation.mutate(mutationData, {
        onSuccess: () => {
          appointmentDrawer.closeWithoutConfirmation();
          resolve();
        },
        onError: (err) => {
          reject(err);
        },
      });
    });

  const dayParams = buildDayParams(selectedDay, selectedShowrooms);

  if (isError) {
    LogService.error("error displaying sales campaign calendar");
    LogService.error(error);
    return (
      <div className="flex items-center justify-center my-10">
        {isError && <div>{`Ooops ${error}`}</div>}
      </div>
    );
  }

  const ongoingSeasonsOptions = Object.keys(
    groupBy(allOngoingShowrooms, (s) => combineYearAndSeason(s.season, s.year)),
  ).map((seasonYear) => ({
    label: seasonYear,
    value: seasonYear,
  }));

  const dailyCalendarFiltered =
    dailyCalendar
      ?.map((seller) => {
        // an appointment has to be from the same showroom and the same collection
        const combinations = Object.entries(
          calendarFilters.filterManager.values,
        ).flatMap(([showroomId, brandCollectionFilter]) =>
          Object.values(brandCollectionFilter).flatMap((collectionIds) => ({
            showroomId,
            collectionIds,
          })),
        );

        return {
          ...seller,
          appointments: seller.appointments.filter(
            (appt) =>
              // always display busy appointments
              appt.type !== AppointmentTypeEnum.BUYING_APPOINTMENT ||
              // display account appointments only if they match the filter
              combinations.find(
                (combination) =>
                  combination.showroomId === appt.showroom.id &&
                  combination.collectionIds.includes(appt.collection?.id || ""),
              ),
          ),
        };
      })
      .filter((seller) =>
        wildcardFilter.length > 0
          ? fullName(seller)
              .toLowerCase()
              .includes(wildcardFilter.toLowerCase())
          : true,
      ) || [];

  return (
    <>
      <div className="flex items-center gap-4 p-4">
        <span className="text-2xl font-bold">{t("Calendar.page-title")}</span>
        <SeasonSelect
          value={{
            season,
            year: parseInt(year, 10),
          }}
          onChange={(s, y) => {
            navigate(
              `/calendar/${s}/${y}/daily/${format(selectedDay, "yyyy-MM-dd")}`,
            );
          }}
          options={ongoingSeasonsOptions}
        />
        <CalendarDatePicker
          showrooms={selectedShowrooms}
          selectedDate={selectedDay}
          onSelectDate={handleSelectDate}
          handlePrint={handlePrint}
        />
      </div>
      <div
        ref={componentRef}
        className="flex flex-col items-stretch w-full h-full lg:h-screen"
      >
        {dailyCalendar && (
          <div className="flex gap-4">
            <Transition
              show={showFilters}
              className="transition-all ease-in-out duration-500 max-w-[20rem] p-4 flex flex-col gap-4"
              enterFrom="-translate-x-full"
              enterTo="-translate-x-0"
              leaveFrom="-translate-x-0"
              leaveTo="-translate-x-full"
            >
              <CalendarFilters {...calendarFilters} />
            </Transition>

            <button
              title={t(showFilters ? "hide-filter" : "show-filter")}
              className="bg-primaryLightElectricBlue/30 transition-colors duration-200 ease-in-out delay-200 hover:delay-0 hover:bg-primaryLightElectricBlue flex flex-col justify-center items-center border-r border-r-primaryLightElectricBlue"
              type="button"
              onClick={() => setShowFilters(!showFilters)}
            >
              {showFilters ? (
                <HiChevronLeft className="w-4 h-4 fill-primaryElectricBlue" />
              ) : (
                <HiChevronRight className="w-4 h-4 fill-primaryElectricBlue" />
              )}
            </button>
            <ConditionalRender
              renderIf={!isLoading && dailyCalendarFiltered.length > 0}
              fallback={
                <div className="flex items-center justify-center my-10">
                  {isLoading && <Loading type="screen" />}
                </div>
              }
            >
              <ScrollView
                root={{
                  className: `${showFilters ? "w-3/4" : "w-full"}
                h-[calc(100vh-16rem)] lg:h-[calc(100vh-8rem)]`,
                }}
              >
                <div className="sticky top-0 z-header">
                  <DailyHeader sellers={dailyCalendarFiltered} />
                </div>
                <div
                  className="ml-10 grid"
                  style={{
                    gridTemplateColumns: `50px repeat(${dailyCalendarFiltered.length}, ${COLUMN_WIDTH}rem)`,
                  }}
                >
                  <TimeColumn interval={dayParams.calendar} />
                  <DndContext
                    sensors={sensors}
                    collisionDetection={pointerWithin}
                    onDragStart={() => {
                      //* @todo grey out every cell that can't receive the dragged appointment
                      // seller not in the showroom
                      // seller already has an appointment (might not be displayed)
                    }}
                    onDragEnd={(ev: DragEndEvent) => {
                      const formData =
                        CalendarHelper.createFormDataFromDragEndEvent(
                          ev,
                          dailyCalendar,
                        );
                      if (formData) {
                        handleSubmitAppointment(formData);
                      }
                    }}
                  >
                    {dailyCalendarFiltered.map((seller) => (
                      <SellerAgenda
                        key={`seller-agenda-${seller.id}`}
                        dayParams={dayParams}
                        seller={seller}
                        onClickAppointmentSlot={handleClickAppointmentSlot}
                        handleBookAppointment={handleClickAvailableSlot}
                        handleBlockSlot={handleBlockAvailableSlot}
                      />
                    ))}
                  </DndContext>
                </div>
              </ScrollView>
            </ConditionalRender>
          </div>
        )}
      </div>

      <Drawer {...appointmentDrawer.props}>
        <DrawerBody>
          {accountAppointmentFormData && (
            <AppointmentDialogEdit
              showrooms={allSeasonShowrooms}
              otherAppointments={(dailyCalendar || []).flatMap(
                (s) => s.appointments,
              )}
              availableCollections={
                findShowroom(
                  accountAppointmentFormData.showroomId,
                  selectedShowrooms,
                ).collections
              }
              defaultValues={accountAppointmentFormData}
              appointment={selectedAccountAppointment}
              organizationId={organizationId}
              onCancel={() => {
                if (!selectedAccountAppointment?.id) {
                  appointmentDrawer.closeWithoutConfirmation();
                } else {
                  // go back to viewing the appointment
                  setAccountAppointmentFormData(undefined);
                }
              }}
              onSubmit={handleSubmitAppointment}
            />
          )}
          {!accountAppointmentFormData && selectedAccountAppointment && (
            <>
              <AppointmentDialogView appointment={selectedAccountAppointment} />
              <div className="flex justify-end w-full gap-5">
                <button
                  type="button"
                  className="flex items-center justify-center h-10 gap-2 cursor-pointer text-primaryRed w-54"
                  onClick={() => setIsOpenCancelDialog(true)}
                >
                  <VscTrash />
                  <span>{t("Calendar.appointment.button.cancel")}</span>
                </button>
                <Button
                  onClick={() =>
                    handleEditSelectedAccountAppointment(
                      selectedAccountAppointment,
                    )
                  }
                  theme="PRIMARY"
                >
                  <HiOutlinePencil />
                  <span>{t("Calendar.appointment.button.edit")}</span>
                </Button>
              </div>
            </>
          )}
        </DrawerBody>
      </Drawer>

      <Drawer
        {...busyAppointmentDrawer.props}
        drawerTitle={
          <h2 className="text-3xl font-medium">
            {t(
              selectedBusyAppointment
                ? selectedBusyAppointment.title
                : "Calendar.DailyCalendarPage.block-appointment-drawer.title",
            )}
          </h2>
        }
      >
        <DrawerBody>
          {selectedBusyAppointment ? (
            <BusySlotEditForm
              appointment={selectedBusyAppointment}
              organizationId={organizationId}
              onCancel={busyAppointmentDrawer.closeWithoutConfirmation}
              onDelete={busyAppointmentDrawer.closeWithoutConfirmation}
              showroomDates={allOngoingShowrooms.flatMap((s) =>
                s.openingDays.map((od) => od.day),
              )}
              sellers={allOngoingShowrooms
                .flatMap((s) => s.sellers)
                .filter(arrayFilterDuplicateIds)}
              onSubmit={busyAppointmentDrawer.closeWithoutConfirmation}
              initialValues={BusyAppointmentEditForm.toFormData(
                selectedBusyAppointment,
              )}
            />
          ) : (
            <BusySlotCreateForm
              onCancel={busyAppointmentDrawer.closeWithoutConfirmation}
              onSubmit={(values) => {
                Promise.allSettled(
                  values.showroomIds.map((showroomId) => {
                    const showroom = findShowroom(
                      showroomId,
                      allSeasonShowrooms,
                    );
                    return createBusySlotMutation.mutateAsync({
                      showroomId,
                      title: values.title,
                      sellerIds: values.sellers.map((s) => s.value),
                      slots: values.dates.map((d) => ({
                        startTime: ZonedDate.fromLocalDate(
                          combineDateAndTime(d, values.start) as LocalDate,
                          showroom.timezone,
                        ),
                        endTime: ZonedDate.fromLocalDate(
                          combineDateAndTime(d, values.end) as LocalDate,
                          showroom.timezone,
                        ),
                      })),
                    });
                  }),
                ).then(busyAppointmentDrawer.closeWithoutConfirmation);
              }}
              defaultValues={
                selectedAvailableSlot
                  ? {
                      dates: [selectedAvailableSlot.start],
                      sellers: [
                        {
                          label: selectedAvailableSlot.sellerName,
                          value: selectedAvailableSlot.sellerId,
                        },
                      ],
                      start: selectedAvailableSlot.start,
                      end: selectedAvailableSlot.end,
                      showroomIds: calendarFilters.selectedShowroomIds,
                    }
                  : {}
              }
              showrooms={allSeasonShowrooms}
              sellers={allSeasonShowrooms.flatMap((s) => s.sellers)}
            />
          )}
        </DrawerBody>
      </Drawer>

      {selectedAccountAppointment && (
        <ConfirmModal
          show={isOpenCancelDialog}
          title={t("Calendar.appointment.delete-dialog.title")}
          confirmLabel="Yes, delete"
          onCancel={() => {
            setIsOpenCancelDialog(false);
          }}
          onConfirm={() => handleCancelAppointment(selectedAccountAppointment)}
          isConfirmLoading={deleteMutation.isPending}
        >
          <span>
            {t("Calendar.appointment.delete-dialog.appointment-date")}&nbsp;
            <strong className="font-medium">
              {selectedAccountAppointment.startTime.formatLocalizedDateAtTimezone(
                selectedAccountAppointment?.showroom.timezone,
              )}
            </strong>
            &nbsp;
            {t("Calendar.appointment.delete-dialog.appointment-name")}&nbsp;
            <strong className="font-medium">
              {selectedAccountAppointment.account.name}
            </strong>
            .&nbsp;
            {t("Calendar.appointment.delete-dialog.question-are-you-sure")}
          </span>
        </ConfirmModal>
      )}
    </>
  );
}
