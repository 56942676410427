import React, { useMemo, useState } from "react";

import { useQueries } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import Tooltip from "@components/data-display/Tooltip";
import Loading from "@components/feedback/Loading";
import { formatDateToISO } from "@helpers/Date";
import { Showroom } from "@models/Showroom";
import { InvitationStatusEnum } from "@models/types/enums";
import { GetInvitations } from "@services/api/sales-campaigns/get-invitations";
import { GetOngoingShowroomsEndpoint } from "@services/api/showroom/get-ongoing-showrooms";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import ConditionalRender from "@shared/components/on";

interface Invitation {
  id: string;
  invitationStatus: InvitationStatusEnum;
  showroomId: Showroom["id"];
}

export function calculateInvitationStatusStatistics(invitations: Invitation[]) {
  const numberOfTotalInvitations = invitations.length;
  const numberOfBookedInvitations = invitations.filter(
    (inv) =>
      inv.invitationStatus === "BOOKED" ||
      inv.invitationStatus === "BOOKED_BY_ORGANIZATION",
  ).length;
  const numberOfFollowedUpInvitations = invitations.filter(
    (inv) => inv.invitationStatus === "FOLLOWED_UP",
  ).length;
  const numberOfNotBookedInvitations = invitations.filter(
    (inv) => inv.invitationStatus === "INVITED",
  ).length;

  return {
    numberOfTotalInvitations,
    numberOfBookedInvitations,
    numberOfFollowedUpInvitations,
    numberOfNotBookedInvitations,
  };
}

function InvitationStatus() {
  const { t } = useTranslation();

  // organization Id
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  // today's date
  const todaysDate = formatDateToISO(new Date());

  //* fetch ongoing showrooms
  const { data: allOngoingShowrooms = [], status: allOngoingShowroomsStatus } =
    GetOngoingShowroomsEndpoint.useHook({
      organizationId,
    });

  //* fetch invitations data
  const { data: rawInvitations } = useQueries({
    queries: allOngoingShowrooms.map((ongoingShowroom) => ({
      queryKey: ["invitations", organizationId, ongoingShowroom.id, todaysDate],
      queryFn: () =>
        GetInvitations.call({
          organizationId,
          season: ongoingShowroom.season,
          year: ongoingShowroom.year,
        }),
    })),
    combine: (queries) => ({
      data: queries.flatMap((q) => q.data || []),
      error: queries.find((q) => q.error)?.error,
      status:
        queries.find((q) => q.status === "error")?.status ||
        queries.find((q) => q.status === "pending")?.status ||
        "success",
    }),
  });

  const invitationsForOngoingShowrooms = useMemo(() => {
    const ongoingShowroomIds = allOngoingShowrooms.map(
      (showroom) => showroom.id,
    );
    return rawInvitations.filter((invitation) =>
      ongoingShowroomIds.includes(invitation.showroomId),
    );
  }, [allOngoingShowrooms, rawInvitations]);

  const [selectedShowroomId, setSelectedShowroomId] = useState<string | "all">(
    "all",
  );

  // set the filtered invitations
  const filteredInvitations = useMemo(() => {
    if (selectedShowroomId === "all") {
      return invitationsForOngoingShowrooms;
    }

    return invitationsForOngoingShowrooms.filter(
      (invitation) => invitation.showroomId === selectedShowroomId,
    );
  }, [invitationsForOngoingShowrooms, selectedShowroomId]);

  const {
    numberOfTotalInvitations,
    numberOfBookedInvitations,
    numberOfFollowedUpInvitations,
    numberOfNotBookedInvitations,
  } = useMemo(
    () => calculateInvitationStatusStatistics(filteredInvitations),
    [filteredInvitations],
  );

  const isLoading = allOngoingShowroomsStatus === "pending";

  const total = numberOfTotalInvitations;
  const bookedPercent = (numberOfBookedInvitations / total) * 100;
  const followedUpPercent = (numberOfFollowedUpInvitations / total) * 100;
  const notBookedPercent = (numberOfNotBookedInvitations / total) * 100;

  return (
    <div>
      <div className="flex flex-row justify-between">
        <div className="flex items-center">
          <h3 className="font-bold">
            {t("Dashboard.invitation-status.title")}
          </h3>
          <Tooltip
            placement="bottom-start"
            renderIf={numberOfTotalInvitations > 0}
            fallbackProp="children"
            content={
              <div className="min-w-[5rem] max-w-[21rem]">
                <p className="text-center">
                  Total Invitations: {numberOfTotalInvitations}
                </p>
              </div>
            }
          >
            <div className="bg-primaryElectricBlue w-8 h-8 rounded-full flex items-center justify-center text-white ml-1">
              {numberOfTotalInvitations}
            </div>
          </Tooltip>
        </div>
        {allOngoingShowrooms.length > 1 && (
          <div className="flex flex-row border border-transparent rounded-md">
            <p className="flex items-center text-sm p-1 bg-primaryLightestGrey text-primaryDarkGrey rounded-l-md">
              {t("Dashboard.invitation-status.for-showroom")}
            </p>
            <select
              id="seller-dropdown"
              value={selectedShowroomId}
              onChange={(e) => {
                setSelectedShowroomId(e.target.value as string);
              }}
              className="form-select block border rounded-r-md shadow-sm border-primaryElectricBlue focus:border-primaryElectricBlue bg-primaryLightElectricBlue sm:text-sm text-primaryElectricBlue caret-primaryElectricBlue"
            >
              <option value="all">All</option>
              {allOngoingShowrooms?.map((showroom) => (
                <option key={showroom.id} value={showroom.id}>
                  {showroom.name}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
      {isLoading ? (
        <Loading type="screen" />
      ) : (
        <ConditionalRender
          renderIf={numberOfTotalInvitations > 0}
          fallback={
            <div className="flex items-center justify-center h-64">
              <p className="text-lg text-gray-500">
                {t("Dashboard.todays-appointments.no-appointments")}
              </p>
            </div>
          }
        >
          {/* Progress Bar */}
          <div className="w-full bg-gray-200 rounded-lg overflow-hidden flex mt-10">
            {numberOfBookedInvitations > 0 && (
              <div
                className="bg-progressBarGreen h-8 text-center text-white"
                style={{
                  width: `${bookedPercent}%`,
                }}
              />
            )}

            {numberOfFollowedUpInvitations > 0 && (
              <div
                className="bg-progressBarBlue h-8 text-center text-white"
                style={{
                  width: `${followedUpPercent}%`,
                }}
              />
            )}
            {numberOfNotBookedInvitations > 0 && (
              <div
                className="bg-progressBarYellow h-8 text-center text-white"
                style={{
                  width: `${notBookedPercent}%`,
                }}
              />
            )}
          </div>
          {/* Legend */}
          <div className="flex justify-end mt-10">
            <div className="flex items-center text-sm p-1 m-1 bg-primaryLightestGrey text-primaryDarkGrey rounded-md">
              <div className="bg-progressBarGreen w-3 h-3 mr-2" />
              <p>
                {t("Dashboard.invitation-status.booked")}:{" "}
                {numberOfBookedInvitations} ({bookedPercent.toFixed(1)}%)
              </p>
            </div>
            <div className="flex items-center text-sm p-1 m-1 bg-primaryLightestGrey text-primaryDarkGrey rounded-md">
              <div className="bg-progressBarBlue w-3 h-3 mr-2" />
              <p>
                {t("Dashboard.invitation-status.followed-up")}:{" "}
                {numberOfFollowedUpInvitations} ({followedUpPercent.toFixed(1)}
                %)
              </p>
            </div>
            <div className="flex items-center text-sm p-1 m-1 bg-primaryLightestGrey text-primaryDarkGrey rounded-md">
              <div className="bg-progressBarYellow w-3 h-3 mr-2" />
              <p>
                {t("Dashboard.invitation-status.non-booked")}:{" "}
                {numberOfNotBookedInvitations} ({notBookedPercent.toFixed(1)}%)
              </p>
            </div>
          </div>
        </ConditionalRender>
      )}
    </div>
  );
}

export default InvitationStatus;
