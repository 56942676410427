import React from "react";

import { useTranslation } from "react-i18next";

function OverallPerformance() {
  const { t } = useTranslation();
  return (
    <div className="p-5 text-center auto-0">
      <p className="text-2xl pb-5 text-primaryElectricBlue font-bold">
        {t("Dashboard.overall-performance.coming-soon")}
      </p>
      <p className="text-sm">
        {t("Dashboard.overall-performance.overall-performance-statistics")}
      </p>
    </div>
  );
}

export default OverallPerformance;
