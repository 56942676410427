import React, { useState } from "react";

import { cva } from "class-variance-authority";
import Gravatar from "react-gravatar";
import { useTranslation } from "react-i18next";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi2";
import { Link, Outlet, useLocation } from "react-router-dom";

import modaresaSmallLogo from "@app/assets/logos/moda-resa-small-logo.png";
import modaresaLogo from "@app/assets/logos/moda-resa.svg";
import HTML from "@components/html";
import { User } from "@models/User";
import useAuthentication from "@services/authentication/useAuthentication";
import { withBuyerOnly } from "@shared/components/guards/authentication";
import { fullName } from "@shared/helpers/formatters";

const navItem = cva("block px-4 py-2 rounded-lg text-sm font-medium ", {
  variants: {
    active: {
      true: " bg-gray-100 text-gray-700",
      false: "text-gray-700",
    },
  },
});

const navBar = cva(
  "flex max-md:hidden h-screen flex-col justify-between border-e bg-white md:col-span-3 lg:col-span-3 xl:col-span-2",
  {
    variants: {
      folded: {
        true: "w-16",
        false: "",
      },
    },
  },
);

function BuyerLayout({ user }: { user: User }) {
  const { signOut, stopImpersonation, impersonation } = useAuthentication();
  const { t } = useTranslation();
  const location = useLocation().pathname;
  const [folded, setFolded] = useState(false);

  return (
    <div className="w-full h-full grid grid-cols-1 md:grid-cols-12">
      <HTML.Div className={navBar({ folded })}>
        <div className="px-4 py-6">
          <span className="flex gap-2">
            <button type="button" onClick={() => setFolded(!folded)}>
              {folded ? <HiChevronRight /> : <HiChevronLeft />}
            </button>
            {folded ? (
              <img src={modaresaSmallLogo} alt="modaresa Logo" />
            ) : (
              <img
                className="m-auto h-5"
                alt="modaresa logo"
                src={modaresaLogo}
              />
            )}
          </span>
          <ul className="mt-6 space-y-1">
            <li>
              <Link
                to="/buyer/dashboard"
                className={navItem({ active: location === "/buyer/dashboard" })}
              >
                {t("BuyerLayout.dashboard")}
              </Link>
            </li>
            <li>
              <Link
                to="/buyer/invitations"
                className={navItem({
                  active: location === "/buyer/invitations",
                })}
              >
                {t("BuyerLayout.invitations")}
              </Link>
            </li>
            <li>
              <Link
                to="/buyer/crm"
                className={navItem({ active: location === "/buyer/crm" })}
              >
                {t("BuyerLayout.crm")}
              </Link>
            </li>
            <li>
              <Link
                to="/buyer/order-deadlines"
                className={navItem({
                  active: location === "/buyer/order-deadlines",
                })}
              >
                {t("BuyerLayout.order-deadlines")}
              </Link>
            </li>
          </ul>
        </div>
        <div className="sticky inset-x-0 bottom-0 border-t border-gray-100">
          <span className="flex items-center gap-2 bg-white p-4 hover:bg-gray-50">
            <Gravatar className="rounded-full" email={user.email} />
            <div>
              <p className="text-xs">
                <strong className="block font-medium">{fullName(user)}</strong>
                <span> {user.email} </span>
              </p>
            </div>
          </span>
          <div className="flex">
            {impersonation && (
              <button
                type="button"
                onClick={stopImpersonation}
                className="w-full bg-gray-400 hover:bg-red-500 text-white font-medium text-sm py-2"
              >
                {t("Stop impersonation")}
              </button>
            )}
            <button
              type="button"
              onClick={signOut}
              className="w-full bg-gray-400 hover:bg-red-500 text-white font-medium text-sm py-2"
            >
              {t("Sign out")}
            </button>
          </div>
        </div>
      </HTML.Div>
      <div className="col-span-11">
        <Outlet />
      </div>
    </div>
  );
}

export default withBuyerOnly(BuyerLayout);
