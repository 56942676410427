import React, { useMemo } from "react";

import { useQueries } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { DashboardPanel } from "@components/data-display/DashboardPanel";
import { formatDateToISO } from "@helpers/Date";
import { Account } from "@models/Account";
import { Appointment as AppointmentModel } from "@models/Appointment";
import { GetDailyCalendarEndpoint } from "@services/api/sales-campaigns/get-daily-calendar";
import { GetOngoingShowroomsEndpoint } from "@services/api/showroom/get-ongoing-showrooms";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";

interface Appointment {
  id: AppointmentModel["id"];
  status: AppointmentModel["status"];
  account: Pick<Account, "id">;
}

export function calculateAppointmentStatusStatistics(
  appointments: Appointment[],
) {
  const totalAppointmentsToday = appointments.length;
  const ongoingAppointmentsNow = appointments.filter(
    (appt) => appt.status === "ONGOING",
  ).length;
  const buyersWaitingNow = appointments.filter(
    (appt) => appt.status === "BUYER_ARRIVED",
  ).length;
  const completedAppointmentsToday = appointments.filter(
    (appt) => appt.status === "DONE",
  ).length;
  const canceledAppointmentsToday = appointments.filter(
    (appt) => appt.status === "CANCELLED",
  ).length;

  return {
    totalAppointmentsToday,
    ongoingAppointmentsNow,
    buyersWaitingNow,
    completedAppointmentsToday,
    canceledAppointmentsToday,
  };
}

export function AppointmentStatus() {
  const { t } = useTranslation();

  // organization Id
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  // showroom Ids
  const { data: allOngoingShowrooms = [] } =
    GetOngoingShowroomsEndpoint.useHook({
      organizationId,
    });

  // today's date
  const todaysDate = formatDateToISO(new Date());

  //* get daily calendar data
  const { data: rawDailyCalendar } = useQueries({
    queries: allOngoingShowrooms.map((ongoingShowroom) =>
      GetDailyCalendarEndpoint.query({
        organizationId,
        showroomId: ongoingShowroom.id,
        dayAsString: todaysDate,
      }),
    ),
    combine: (queries) => ({
      data: queries.flatMap((q) => q.data || []),
      error: queries.find((q) => q.error)?.error,
      status:
        queries.find((q) => q.status === "error")?.status ||
        queries.find((q) => q.status === "pending")?.status ||
        "success",
    }),
  });

  // structure and sort all appointments from the raw daily calendar data
  const allAppointments = rawDailyCalendar
    .filter((day) => day.appointments.length > 0)
    .flatMap((day) => day.appointments)
    .filter((app) => app.type !== "BUSY")
    .sort((a, b) => {
      // convert start times to local date/time and get the timestamps
      const startTimeA = new Date(
        a.startTime.toLocalDate(a.showroom.timezone),
      ).getTime();
      const startTimeB = new Date(
        b.startTime.toLocalDate(b.showroom.timezone),
      ).getTime();

      // compare the timestamps
      return startTimeA - startTimeB;
    });

  // memoize the appointment status statistics
  const {
    totalAppointmentsToday,
    ongoingAppointmentsNow,
    buyersWaitingNow,
    completedAppointmentsToday,
    canceledAppointmentsToday,
  } = useMemo(
    () => calculateAppointmentStatusStatistics(allAppointments),
    [allAppointments],
  );

  return (
    <>
      <DashboardPanel className="col-span-2 h-full">
        <div className="h-12 xl:h-auto">
          <h3 className="font-bold text-center">
            {t("Dashboard.appointment-status.total-appointments")}
          </h3>
        </div>
        <div className="pt-5 text-center">
          <p className="text-4xl font-bold text-primaryElectricBlue">
            {totalAppointmentsToday}
          </p>
          <p className="font-light text-sm text-center pt-4">
            {totalAppointmentsToday === 1
              ? `${t("Dashboard.appointment-status.appointment")}`
              : `${t("Dashboard.appointment-status.appointments")}`}
          </p>
        </div>
      </DashboardPanel>
      <DashboardPanel className="col-span-2 h-full">
        <div className="h-12 xl:h-auto">
          <h3 className="font-bold text-center">
            {t("Dashboard.appointment-status.completed-appointments")}
          </h3>
        </div>
        <div className="pt-4 text-center">
          <p className="text-4xl font-bold text-primaryElectricBlue">
            {completedAppointmentsToday}
          </p>
          <p className="font-light text-sm text-center pt-4">
            {completedAppointmentsToday === 1
              ? `${t("Dashboard.appointment-status.appointment")}`
              : `${t("Dashboard.appointment-status.appointments")}`}
          </p>
        </div>{" "}
      </DashboardPanel>
      <DashboardPanel className="col-span-2 h-full">
        <div className="h-12 xl:h-auto">
          <h3 className="font-bold text-center">
            {t("Dashboard.appointment-status.canceled-appointments")}
          </h3>
        </div>
        <div className="pt-4 text-center">
          <p className="text-4xl font-bold text-primaryElectricBlue">
            {canceledAppointmentsToday}
          </p>
          <p className="font-light text-sm text-center pt-4">
            {canceledAppointmentsToday === 1
              ? `${t("Dashboard.appointment-status.appointment")}`
              : `${t("Dashboard.appointment-status.appointments")}`}
          </p>
        </div>{" "}
      </DashboardPanel>
      <DashboardPanel className="col-span-2 h-full">
        <div className="h-12 xl:h-auto">
          <h3 className="font-bold text-center">
            {t("Dashboard.appointment-status.ongoing-appointments")}
          </h3>
        </div>
        <div className="pt-4 text-center">
          <p className="text-4xl font-bold text-primaryElectricBlue">
            {ongoingAppointmentsNow}
          </p>
          <p className="font-light text-sm text-center pt-4">
            {ongoingAppointmentsNow === 1
              ? `${t("Dashboard.appointment-status.appointment")}`
              : `${t("Dashboard.appointment-status.appointments")}`}
          </p>
        </div>
      </DashboardPanel>
      <DashboardPanel className="col-span-2 h-full">
        <div className="h-12 xl:h-auto">
          <h3 className="font-bold text-center">
            {t("Dashboard.appointment-status.buyers-arrived")}
          </h3>
        </div>
        <div className="pt-4 text-center">
          <p className="text-4xl font-bold text-primaryElectricBlue">
            {buyersWaitingNow}
          </p>
          <p className="font-light text-sm text-center pt-4">
            {buyersWaitingNow === 1
              ? `${t("Dashboard.appointment-status.appointment")}`
              : `${t("Dashboard.appointment-status.appointments")}`}
          </p>
        </div>{" "}
      </DashboardPanel>
    </>
  );
}
