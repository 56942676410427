import React from "react";

import { User } from "@models/User";
import { withBuyerOnly } from "@shared/components/guards/authentication";

function BuyerInvitationsPage({ user }: { user: User }) {
  return <div>Buyer Invitations Page : {user.email}</div>;
}

export default withBuyerOnly(BuyerInvitationsPage);
