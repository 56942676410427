/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";

import { useTranslation } from "react-i18next";
import { HiChevronDown, HiXMark } from "react-icons/hi2";
import Select, {
  ClearIndicatorProps,
  ContainerProps,
  ControlProps,
  DropdownIndicatorProps,
  GroupBase,
  GroupHeadingProps,
  MenuProps,
  MultiValueGenericProps,
  MultiValueRemoveProps,
  NoticeProps,
  OptionProps,
  Props,
  components,
} from "react-select";

import Tag from "@components/data-display/Tag";
import { CheckboxMark } from "@components/data-entry/Checkbox";

export const REACT_SELECT_LANG = {
  REMOVE_ALL_OPTIONS: "Common.remove-all-options",
  REMOVE_OPTION: "Common.remove-option",
  SELECT_CONTROL: "Common.select-control",
  NO_OPTIONS_AVAILABLE: "Common.no-options-available",
};

export interface SelectOptionBase {
  label: string;
  value: string;
  disabled?: boolean;
}

function DropdownIndicator(props: DropdownIndicatorProps<SelectOptionBase>) {
  const {
    selectProps: { menuIsOpen },
  } = props;
  return (
    <components.DropdownIndicator {...props} className="pr-3">
      <HiChevronDown
        className={`fill-primaryDarkGrey transition-all duration-200 ${
          menuIsOpen ? "rotate-180" : ""
        }`}
      />
    </components.DropdownIndicator>
  );
}

function ClearIndicator(props: ClearIndicatorProps<SelectOptionBase>) {
  const { innerProps, hasValue } = props;
  const { t } = useTranslation();
  return (
    <components.ClearIndicator
      {...props}
      innerProps={{
        ...innerProps,
        "aria-hidden": !hasValue,
        role: "button",
        "aria-label": t(REACT_SELECT_LANG.REMOVE_ALL_OPTIONS),
      }}
    >
      <HiXMark className="fill-primaryDarkGrey cursor-pointer" />
    </components.ClearIndicator>
  );
}

function MultiValueContainer(props: MultiValueGenericProps<SelectOptionBase>) {
  const { children } = props;
  return (
    <components.MultiValueContainer {...props}>
      <Tag>{children}</Tag>
    </components.MultiValueContainer>
  );
}

function MultiValueRemove({
  innerProps,
  ...props
}: MultiValueRemoveProps<SelectOptionBase>) {
  const { t } = useTranslation();
  return (
    <components.MultiValueRemove
      {...props}
      innerProps={{
        ...innerProps,
        "aria-label": `${t(REACT_SELECT_LANG.REMOVE_OPTION)} ${
          props.data.label
        }`,
      }}
    />
  );
}

function Menu(props: MenuProps<SelectOptionBase>) {
  const {
    selectProps: { name },
    innerProps,
  } = props;
  return (
    <components.Menu
      {...props}
      innerProps={{ ...innerProps, role: "listbox", "aria-label": name }}
    />
  );
}

function GroupHeading(props: GroupHeadingProps<SelectOptionBase>) {
  const {
    data: { label },
  } = props;
  return <components.GroupHeading {...props} role="group" aria-label={label} />;
}

function Option(props: OptionProps<SelectOptionBase>) {
  const {
    children,
    data: { label, value },
    innerProps,
    isSelected,
    isMulti,
  } = props;
  return (
    <components.Option
      {...props}
      innerProps={{
        ...innerProps,
        role: "listitem",
        "aria-label": label,
        style: {
          ...innerProps.style,
          display: "flex",
          alignItems: "center",
        },
      }}
    >
      {isMulti ? <CheckboxMark state={isSelected} /> : null}
      {children}
    </components.Option>
  );
}

function Control({ innerProps, ...props }: ControlProps<SelectOptionBase>) {
  const { t } = useTranslation();
  return (
    <components.Control
      {...props}
      innerProps={{
        ...innerProps,
        style: {
          ...innerProps.style,
          minHeight: "2.6rem",
          opacity: props.isDisabled ? 0.5 : 1,
          backgroundColor: props.isDisabled ? "#f0f0f0" : "white",
        },
        role: "button",
        "aria-label": t(REACT_SELECT_LANG.SELECT_CONTROL),
      }}
    />
  );
}

function NoOptionsMessage(props: NoticeProps<SelectOptionBase>) {
  const { t } = useTranslation();
  return (
    <components.NoOptionsMessage {...props}>
      {t(REACT_SELECT_LANG.NO_OPTIONS_AVAILABLE)}
    </components.NoOptionsMessage>
  );
}

function SelectContainerGenerator(label?: string) {
  return function C({ children, ...props }: ContainerProps<SelectOptionBase>) {
    return (
      <div role="group" aria-labelledby={label}>
        <components.SelectContainer {...props}>
          {children}
        </components.SelectContainer>
      </div>
    );
  };
}

export type ReactSelectWrapperProps<IsMulti extends boolean> = Props<
  SelectOptionBase,
  IsMulti,
  GroupBase<SelectOptionBase>
>;

export default function ReactSelectWrapper<IsMulti extends boolean>({
  ...props
}: ReactSelectWrapperProps<IsMulti>) {
  return (
    <Select
      isOptionDisabled={(option) => !!option.disabled}
      unstyled
      components={{
        DropdownIndicator,
        ClearIndicator,
        MultiValueContainer,
        Menu,
        GroupHeading,
        Option,
        NoOptionsMessage,
        Control,
        MultiValueRemove,
        SelectContainer: SelectContainerGenerator(props["aria-labelledby"]),
      }}
      classNames={{
        control: ({ isDisabled }) =>
          `min-w-[10rem] bg-white rounded-lg min-h-[2.5rem] border border-primaryGrey ${
            isDisabled ? "bg-primaryLightestGrey" : ""
          }`,
        placeholder: () => "text-primaryGrey",
        valueContainer: () => "px-2 py-1 gap-1",
        menu: () => "bg-white border border-gray-300 py-2",
        menuList: () => "",
        option: ({ isFocused, isSelected, isDisabled }) =>
          `flex items-center gap-3 pl-2 py-2 text-primaryBlack w-full ${
            isFocused ? "bg-gray-100" : ""
          } ${isSelected ? "bg-primaryLightElectricBlue" : ""} ${
            isDisabled ? "bg-gray-200" : ""
          }`,
        groupHeading: () => "px-2 leading-9 uppercase text-primaryDarkGrey",
        menuPortal: () => "fixed z-select",
      }}
      {...props}
    />
  );
}
